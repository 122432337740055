@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
}

.mt20 {
  margin-top: 20px;
}

.mt10 {
  margin-top: 10px;
}

#frontMain {
  background-image: url("/images/bgimg.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.text-center {
  text-align: center;
}

.header {
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 1;
  background: #fff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fff,
    #eaeaea
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fff,
    #eaeaea
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  #logo_text {
    position: relative;
    left: 45px;
    top: 15px;
    font-size: 20px;
    font-weight: lighter;
  }

  .logo {
    width: 40px;
    height: 80px;
    background-size: contain;
    margin: auto;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 10px;

    img {
      width: 35px;
    }
  }

  .main_menu {
    line-height: 62px !important;
    margin-left: 58px !important;
    display: flex;
    background: none;
  }

  .ant-menu-submenu {
    padding: 0 !important;
  }

  .ant-menu-overflowed-submenu {
    margin-left: auto;
  }

  .hide-group-title {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

.frontpage_map {
  .ant-card-body {
    padding: 0;
  }
}

.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}

.contentCard {
  .ant-card-head {
    border-bottom: 0;
  }

  .ant-card-head-title {
    padding-bottom: 0;
    padding-top: 30px;
  }

  ul {
    margin-left: 30px;
  }

  li {
    list-style-type: initial;
  }

  max-width: 1100px;
  margin: auto;
  border: none;
  text-align: justify;
  border-radius: 0;
}

.main_cards_frontpage {
  display: flex;
  justify-content: center;
  color: #fff;

  .ant-card {
    max-width: 500px;
    margin: 10px;
    width: 100%;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;

    .ant-card-head {
      background: inherit;
      color: inherit;
    }
  }
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.offices {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-width: 1100px;
  margin: auto;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .ant-card {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    overflow: hidden;

    &:nth-child(3n + 1) {
      .ant-card-head {
        border-bottom: 3px solid #f15b40;
      }
    }

    &:nth-child(3n + 2) {
      .ant-card-head {
        border-bottom: 3px solid #4ac7e9;
      }
    }

    &:nth-child(3n + 3) {
      .ant-card-head {
        border-bottom: 3px solid #5bc4bf;
      }
    }

    .ant-card-head {
      color: #636363;
    }
  }
}

h1 {
  color: rgb(147, 147, 147);
}

.employee {
  display: inline-block;
  border: 1px solid #0000002b;
  padding: 2px 5px;
  border-radius: 100px;
  margin: 2px 2px;
  font-size: 11px;
  background: #fdfdfd;
  cursor: pointer;
}

.richTextContent {
  line-height: 28px;

  img {
    display: block;
    margin-bottom: 10px;
  }
}

.richTextImage {
  margin: auto;
}

.betweener {
  background-image: url(/images/girl.jpg);
  background-size: cover;
  background-position: center;
  opacity: 0.8;

  &.drawing {
    background-image: url(/images/drawing.jpg);
  }
}

.newsItem {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(185, 185, 185, 0.35);
  }
}

.ant-table {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.75);

  a {
    color: #fff !important;
  }

  tr {
    cursor: pointer;

    &:hover {
      color: black !important;

      a {
        color: black !important;
      }
    }
  }
}

#dadi {
  height: 80%;
  width: auto;
  position: sticky;
  right: 20px;
  top: 0;
}

.venueTable {
  margin-top: 20px;
}
